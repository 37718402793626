import React from "react";
import "./AccessibilityStatement.scss";

function AccessibilityStatementContent(props) {
  return (
    <>
      <p>
        <div>
          A brandingraphics אחראי על הקמת והפעלת אתר www.abrandingraphics.com.
        </div>
        <div>
          אנו רואים חשיבות רבה במתן שירות שוויוני לכלל האזרחים ובשיפור השירות
          הניתן לאזרחים עם מוגבלות.
        </div>
        <div>
          אנו משקיעים משאבים רבים בהנגשת האתר והנכסים הדיגיטליים שלנו על מנת
          להפוך את שירותי החברה לזמינים יותר עבור אנשים עם מוגבלות.
        </div>
        <div>
          במדינת ישראל כ-20 אחוזים מקרב האוכלוסייה הינם אנשים עם מוגבלות הזקוקים
          לנגישות דיגיטלית,
        </div>
        <div>על מנת לצרוך מידע ושירותים כללים.</div>
        <div>
          הנגשת האתר של A brandingraphics נועדה להפוך אותו לזמין, ידידותי ונוח
          יותר לשימוש עבור אוכלוסיות עם צרכים מיוחדים,{" "}
        </div>
        <div>
          הנובעים בין היתר ממוגבלויות מוטוריות שונות, לקויות קוגניטיביות, קוצר
          רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אוכלוסייה הנמנית על
          בני הגיל השלישי.
        </div>
        <div>
          הנגשת אתר זה בוצעה על ידי חברת הנגשת האתרים "Vee הנגשת אתרים".
        </div>
      </p>
      <p>
        <div>רמתה נגישות באתר - AA</div>
        <div>
          חברת "Vee", התאימה את נגישות האתר לדפדפנים הנפוצים ולשימוש בטלפון
          הסלולרי ככל הניתן, והשתמשה בבדיקותיה בקוראי מסך מסוג Jaws ו- NVDA.
        </div>
        <div>
          מקפידה על עמידה בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות 5688
          התשע"ג 2013 ברמת AA.{" "}
        </div>
        <div>וכן, מיישמת את המלצות מסמך WCAG2.2 מאת ארגון W3C.</div>
        <div>
          בעברית:{" "}
          <a
            className="accessibility-statement-link"
            href="https://www.isoc.org.il/files/w3c-wai/guidelines.html"
          >
            WCAG2.0 הנחיותלנגישות תכנים באינטרנט
          </a>
        </div>
        <div>
          באנגלית:{" "}
          <a
            className="accessibility-statement-link"
            href="https://www.w3.org/TR/WCAG20/"
          >
            0WebContent Accessibility Guidelines (WCAG) 2.
          </a>
        </div>
        <div>
          הנגשת האתר בוצעה בהתאם{" "}
          <a
            className="accessibility-statement-link"
            href="https://www.gov.il/he/departments/policies/accessibility_of_applications_in_web_browsers"
          >
            להנחיות רשות התקשוב להנגשת יישומים בדפדפני אינטרנט.
          </a>
        </div>
      </p>
      <p>
        <div>כיצד עוברים למצב נגיש?</div>
        <div>
          באתר מוצב אייקון נגישות (בד"כ בדפנות האתר). לחיצה על האייקון מאפשרת
          פתיחת של תפריט הנגישות.{" "}
        </div>
        <div>
          לאחר בחירת הפונקציה המתאימה בתפריט יש להמתין לטעינת הדף ולשינוי הרצוי
          בתצוגה (במידת הצורך).
        </div>
        <div>
          במידה ומעוניינים לבטל את הפעולה, יש ללחוץ על הפונקציה בתפריט פעם שניה.
          בכל מצב, ניתן לאפס הגדרות נגישות.
        </div>
        <div>
          התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera בכפוף
          (תנאי יצרן) הגלישה במצב נגישות מומלצת בדפדפן כרום.
        </div>
        <div>
          האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש
          המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים
          וחלונות
        </div>
        <div>
          לצורך קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש
          בתוכנת NVDA העדכנית ביותר.
        </div>
      </p>
      <p>
        <div>תיקונים והתאמות שבוצעו באתר:</div>
        <div>
          התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS
        </div>
        <div>אמצעי הניווט באתר פשוטים וברורים.</div>
        <div>תכני האתר כתובים באופן ברור, מסודר והיררכי.</div>
        <div>האתר מותאם לצפייה בדפדפנים מודרניים.</div>
        <div>התאמת האתר לתצוגה תואמת מגוון מסכים ורזולוציות.</div>
        <div>כל הדפים באתר בעלי מבנה קבוע(1H/2H/3H וכו').</div>
        <div>לכל התמונות באתר יש הסבר טקסטואלי חלופי (alt).</div>
      </p>
      <p>
        <div>פונקציונליות תוכנת נגישות:</div>
        <div>
          התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS
        </div>
        <div>עצירת הבהובים - עצירת אלמנטים נעים וחסימת אנימציות</div>
        <div>דילוג ישיר לתוכן - דילוג על התפריט הראשי ישירות אל התוכן.</div>
        <div>התאמה לניווט מקלדת</div>
        <div>הגדלה / הקטנה של טקסט</div>
        <div>ריווח בין אותיות / מילים / שורות</div>
        <div>ניגודיות וצבע - גבוהה, הפוכה, שחור לבן</div>
        <div>גופן קריא</div>
        <div>הדגשת קישורים</div>
        <div>מדריך קריאה</div>
        <div>שינוי אייקון סמן עכבר</div>
        <div>תיאור לתמונות</div>
      </p>
      <p>
        <div>החרגות:</div>
        <div>
          חשוב לציין, כי למרות מאמצינו להנגיש את כלל הדפים והאלמנטים באתר, ייתכן
          שיתגלו חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו.
        </div>
        <div>
          אנו פועלים לשפר את נגישות האתר שלנו כל העת, כחלק ממחויבותנו לאפשר לכלל
          האוכלוסייה להשתמש בו,{" "}
        </div>
        <div>כולל אנשים עם מוגבלות.</div>
      </p>
      <p>
        <div>יצירת קשר בנושא נגישות:</div>
        <div>
          במידה ונתקלתם בבעיה בנושא נגישות באתר, נשמח לקבל הערות ובקשות באמצעות
          פנייה לרכז הנגישות שלנו:
        </div>
        <div>
          על מנת שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים
          מלאים ככל שניתן:
        </div>
        <div>
          <li>תיאור הבעיה.</li>
          <li>מהי הפעולה שניסיתם לבצע.</li>
          <li>קישור לדף שבו גלשתם.</li>
          <li>סוג הדפדפן וגרסתו.</li>
          <li>מערכת הפעלה.</li>
          <li>סוג הטכנולוגיה המסייעת (במידה והשתמשתם).</li>
        </div>
        <div>
          A brandingraphics תעשה ככל יכולה על מנת להנגיש את האתר בצורה המיטבית
          ולענות לפניות בצורה המקצועית והמהירה ביותר.
        </div>
      </p>
      <p>
        <div>רכז נגישות:</div>
        <div>אביעד</div>
        <div>0523153355</div>
        <div>aviad@agraphicsltd.com</div>
      </p>
      <p>
        <div>תאריך עדכון הצהרת נגישות - 4.2.2024</div>
      </p>
    </>
  );
}

export default AccessibilityStatementContent;
