import React from "react";

function CloseIcon(props) {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3343_3320)">
        <path
          d="M26.7505 27.9995C26.5213 27.826 26.2677 27.677 26.0661 27.4754C22.1374 23.5586 18.2143 19.6355 14.2919 15.7124C14.2042 15.6248 14.1347 15.5189 13.9231 15.368C13.8598 15.4764 13.816 15.6041 13.7302 15.6899C9.80275 19.623 5.87279 23.5536 1.9372 27.4785C1.73557 27.6795 1.48008 27.8273 1.24964 27.9995H0.749319C0.389261 27.8592 0.143795 27.605 -0.000854492 27.2493C-0.000854492 27.0828 -0.000854492 26.9162 -0.000854492 26.7496C0.179488 26.5135 0.335409 26.2537 0.543303 26.0452C4.46324 22.1152 8.38881 18.1909 12.3144 14.2666C12.3945 14.1864 12.4891 14.1207 12.5937 14.0342C12.4728 13.9071 12.3914 13.8163 12.305 13.7299C8.3525 9.77429 4.39937 5.81928 0.443113 1.86616C0.212676 1.63635 0.0479882 1.39026 0.0561286 1.04898C0.0667738 0.633195 0.25463 0.326988 0.625334 0.154161C0.992906 -0.017415 1.35547 0.0232872 1.68109 0.276267C1.77126 0.345774 1.84765 0.43344 1.92843 0.514219C5.85526 4.43791 9.78146 8.36098 13.707 12.2859C13.7953 12.3742 13.868 12.4769 13.9688 12.5978C14.0915 12.4807 14.1804 12.3993 14.2656 12.3135C18.2269 8.35096 22.1887 4.38907 26.1475 0.424674C26.3905 0.181713 26.6435 -0.0117793 27.0123 -0.00050794C27.4162 0.0120158 27.7092 0.196115 27.8902 0.546154C28.0793 0.911848 28.0405 1.27191 27.7994 1.60253C27.7142 1.719 27.6034 1.81794 27.5001 1.92064C23.5682 5.85372 19.6364 9.78681 15.7033 13.7186C15.6163 13.8057 15.513 13.8764 15.3301 14.0274C15.4942 14.1382 15.6119 14.1933 15.6989 14.2797C19.6571 18.2303 23.6152 22.1816 27.5608 26.1447C27.7599 26.3445 27.934 26.6344 27.9816 26.9068C28.0793 27.461 27.7261 27.7891 27.2496 27.9989H26.7493L26.7505 27.9995Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3343_3320">
          <rect width="28.0106" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;
