import React from "react";

function Logo({
  color = "#fff",
  secondaryColor = "#000",
  width = 105,
  height = 34,
  onClick,
  className,
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 105 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.0081 28.7381C41.0081 30.437 39.4493 31.4704 36.8396 31.4704C34.0898 31.4704 32.4434 30.5596 32.4434 28.9658C32.4434 27.9499 33.2315 27.2143 34.335 27.2143V27.1968C33.8971 26.899 33.6344 26.4261 33.6344 25.8306C33.6344 25.2351 33.8796 24.7973 34.1423 24.6046V24.5696C33.3191 24.0266 32.8637 23.1684 32.8637 22.065C32.8637 20.1909 34.2124 18.9823 36.3842 18.9823C37.3125 18.9823 38.1006 19.21 38.6962 19.6129V19.5954C38.521 19.3677 38.3809 19.0174 38.3809 18.6321C38.3809 17.9139 38.9238 17.4761 39.8346 17.4761C40.2199 17.4761 40.4651 17.5461 40.5878 17.5987V18.3868C40.4827 18.3343 40.255 18.2642 39.9397 18.2642C39.3617 18.2642 39.0114 18.527 39.0114 19.0524C39.0114 19.3852 39.134 19.788 39.3092 20.1909C39.6945 20.6988 39.9047 21.3293 39.9047 22.065C39.9047 23.939 38.556 25.1476 36.3842 25.1476C35.7537 25.1476 35.2107 25.0425 34.7378 24.8673C34.5802 25.0074 34.4576 25.3227 34.4576 25.6905C34.4576 26.3211 34.8604 26.7064 35.6135 26.7064H38.3984C40.0623 26.7064 41.0081 27.4595 41.0081 28.7381ZM40.0623 28.8257C40.0623 27.985 39.4493 27.5296 38.2758 27.5296H35.6135C34.2124 27.5296 33.3892 28.0025 33.3892 28.9308C33.3892 30.0692 34.7028 30.6472 36.8396 30.6472C38.9764 30.6472 40.0623 29.9116 40.0623 28.8257ZM33.792 22.065C33.792 23.4486 34.7728 24.3244 36.3842 24.3244C37.9956 24.3244 38.9764 23.4486 38.9764 22.065C38.9764 20.6813 37.9956 19.8055 36.3842 19.8055C34.7728 19.8055 33.792 20.6813 33.792 22.065Z"
        fill="white"
      />
      <path
        d="M43.041 19.1194H43.9868L43.9518 21.694H43.9693C44.3546 20.1177 45.423 18.9792 47.2271 18.9792C49.1537 18.9792 50.1871 20.2053 50.1871 22.3946C50.1871 22.9026 50.152 23.4455 50.117 23.7433H49.2413C49.2763 23.4806 49.3113 23.0252 49.3113 22.5698C49.3113 20.7482 48.5757 19.8375 47.0344 19.8375C45.1953 19.8375 43.9868 21.3788 43.9868 23.7433V27.7717H43.041V19.1194Z"
        fill="white"
      />
      <path
        d="M52.0781 25.3405C52.0781 24.1145 52.9188 23.0986 55.1958 23.0986H59.049V22.293C59.049 20.6641 57.9456 19.8059 56.054 19.8059C54.3025 19.8059 53.2341 20.524 53.2341 21.8201C53.2341 21.9427 53.2341 22.1003 53.2516 22.2229H52.3759C52.3584 22.1178 52.3408 21.8726 52.3408 21.6975C52.3408 19.9985 53.7946 18.9827 56.0715 18.9827C58.5061 18.9827 59.9948 20.2087 59.9948 22.363V27.7751H59.049C59.049 27.2847 59.0841 26.3564 59.0841 25.5332H59.0665C58.7162 26.9869 57.4026 27.9152 55.2833 27.9152C53.3742 27.9152 52.0781 26.9344 52.0781 25.3405ZM59.049 23.9394V23.8168H55.2833C53.5844 23.8168 52.9714 24.4123 52.9714 25.3405C52.9714 26.4265 53.9697 27.092 55.4585 27.092C57.6303 27.092 59.049 25.901 59.049 23.9394Z"
        fill="white"
      />
      <path
        d="M62.4453 19.1194H63.3911L63.3561 21.5714H63.3736C63.7064 20.0477 65.0375 18.9792 67.0167 18.9792C69.5213 18.9792 71.0976 20.6432 71.0976 23.2879C71.0976 26.1428 69.4688 27.9118 67.0517 27.9118C65.02 27.9118 63.7764 26.9485 63.3561 25.7049H63.3386C63.3561 26.2654 63.3911 27.1762 63.3911 27.5965V31.2396H62.4453V19.1194ZM70.1518 23.3054C70.1518 21.1686 68.8733 19.8375 66.859 19.8375C64.7222 19.8375 63.4261 21.2737 63.3911 23.428V23.9885C63.3911 25.7049 64.6522 27.0536 66.9291 27.0536C68.8207 27.0536 70.1518 25.6349 70.1518 23.3054Z"
        fill="white"
      />
      <path
        d="M73.3574 15.6338H74.3032V19.6972C74.3032 20.1526 74.2682 21.1334 74.2682 21.5538H74.2857C74.7586 19.9599 76.0547 18.9791 78.1214 18.9791C80.4334 18.9791 81.782 20.2402 81.782 22.412V27.7716H80.8362V22.5872C80.8362 20.8357 79.8204 19.8373 77.8587 19.8373C75.7394 19.8373 74.3032 21.1334 74.3032 23.4454V27.7716H73.3574V15.6338Z"
        fill="white"
      />
      <path
        d="M84.1992 16.3171H85.2326V17.946H84.1992V16.3171ZM84.2518 19.1195H85.1976V27.7718H84.2518V19.1195Z"
        fill="white"
      />
      <path
        d="M87.4746 23.4489C87.4746 20.6816 89.156 18.9827 91.6957 18.9827C93.8675 18.9827 95.4088 20.3313 95.4088 22.5031H94.5156C94.5156 20.8568 93.3246 19.8409 91.7132 19.8409C89.7515 19.8409 88.4204 21.207 88.4204 23.4489C88.4204 25.6908 89.7165 27.057 91.6081 27.057C93.307 27.057 94.5506 26.0587 94.5681 24.4298H95.4439C95.4439 26.5841 93.85 27.9152 91.5906 27.9152C89.121 27.9152 87.4746 26.2163 87.4746 23.4489Z"
        fill="white"
      />
      <path
        d="M97.3184 24.8852H98.1941C98.1941 26.2688 98.9998 27.092 100.979 27.092C102.958 27.092 103.904 26.4265 103.904 25.323C103.904 24.8676 103.746 24.4823 103.326 24.2546C102.941 24.062 102.17 23.9394 100.839 23.8168C99.3851 23.6766 98.5269 23.484 98.019 23.1337C97.5461 22.8009 97.3184 22.2229 97.3184 21.5924C97.3184 20.0336 98.7546 18.9827 100.961 18.9827C103.414 18.9827 104.499 20.0861 104.499 22.0653H103.606C103.606 20.5415 102.765 19.8059 100.996 19.8059C99.3501 19.8059 98.2642 20.5065 98.2642 21.5398C98.2642 21.9602 98.3868 22.3981 98.8246 22.6082C99.2625 22.8359 100.103 22.9585 101.364 23.0986C102.853 23.2563 103.484 23.3789 104.027 23.6766C104.569 23.9919 104.85 24.6049 104.85 25.288C104.85 26.9344 103.466 27.9152 100.979 27.9152C98.2817 27.9152 97.3184 26.6542 97.3184 24.8852Z"
        fill="white"
      />
      <path
        d="M22.2683 27.775H26.1006L14.2991 0H10.4669L11.777 3.08261L0 27.775H0.970321L5.16687 18.9791H18.5307L22.2683 27.775ZM5.58372 18.1033L12.2359 4.15802L18.1594 18.1033H5.58372Z"
        fill="white"
      />
    </svg>
  );
}

export default Logo;
